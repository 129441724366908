
import _ from 'lodash';

import { SystemUpgrade } from '../api/src/models/SystemUpgrade';
import uiMixin from '../mixins/ui';
import Quote from './quote';

export default {
  name: 'QuoteSelect',
  components: {
    Quote,
  },
  mixins: [
    uiMixin,
  ],
  computed: {
    upgrade() {
      return this.isDebugger
        ? this.$store.getters['systems/upgrades'].find(u => u.name === SystemUpgrade.Name.enum.QuoteSelectCount)
        : undefined;
    },
    count() {
      let val = 1;

      if (this.upgrade) {
        val = this.upgrade.value;
      }

      return val;
    },
    quoteSelected() {
      const q = this.$store.state.userSettings.quote;
      const hasQuote = !!q && _.isDate(q.expiresAt) && this.$store.state.now.isBefore(q.expiresAt)
      return hasQuote ? q : { id: this.$store.state.quotes.quotesForDay[0].id };
    },
    quotesForDay() {
      return this.$store.state.quotes.quotesForDay
        .slice(0, this.count)
        .map(q => ({
          ...q,
          isSelected: this.quoteSelected?.id === q.id,
        }));
    },
  },
  methods: {
    copyToClipboard(quote) {
      this.uiCopyToClipboard(`"${quote.content}" -- ${quote.author}`);
      this.$store.dispatch('logEvent', { name: 'quote_copy' });
    },
    handleSelect(quote) {
      this.$store.dispatch('userSettings/selectQuote', { id: quote.id });
      this.uiNotify('Quote set.', { logMessage: true });
      this.$emit('close');
    },
    handleViewUpgrades() {
      this.$store.dispatch('ui/showModal', { name: 'systemUpgrades' });
      this.$emit('close');
    },
  },
};
