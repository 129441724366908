import { render, staticRenderFns } from "./quote-select.vue?vue&type=template&id=2768a984&scoped=true&"
import script from "./quote-select.vue?vue&type=script&lang=js&"
export * from "./quote-select.vue?vue&type=script&lang=js&"
import style0 from "./quote-select.vue?vue&type=style&index=0&id=2768a984&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2768a984",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Quote: require('/opt/build/repo/components/quote.vue').default,AppConceptButton: require('/opt/build/repo/components/app-concept-button.vue').default})
